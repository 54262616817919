/* hover button arancio CTA (accedi) #e09d00da */

/* Background */

.bg-primary {
  background-color: #f7b801 !important;
}

.bg-blue {
  background-color: #001f3f !important;
}

.bg-purple {
  background-color: #7678ed !important;
}

.bg-purple-dark {
  background-color: #3d348b;
}

.bg-orange {
  background-color: #f18701 !important;
}

.bg-orange-dark {
  background-color: #f35b04 !important;
}

.bg-light {
  background-color: #ffffff !important;
}

/* background button CTA  */
.button-bg-cta {
  background-color: #f7b801 !important;
}
.button-bg-cta:hover {
  background-color: #e09d00da !important;
}

.button-bg-cta-blue {
  background-color: #001f3f !important;
}
.button-bg-cta-blue:hover {
  background-color: #001f3f !important;
}

.button-cta-white {
  border-radius: 5px;
  width: 280px;
  text-align: center;
  padding: 16px !important;
  transition: background-color 0.5s ease;
  border: 2px solid #f6f6f6 !important;
  background-color: transparent;
}

.button-cta-white:hover {
  background-color: #bfbdbd47;
}

.button-cta-orange {
  border-radius: 5px;
  width: 280px;
  text-align: center;
  padding: 16px !important;
  transition: background-color 0.5s ease;
  border: 2px solid #f18701 !important;
  background-color: transparent;
}

.button-cta-orange:hover {
  background-color: #bfbdbd47;
}

/* color  */
.color-primary {
  color: #001f3f !important;
}

.color-yellow {
  color: #f7b801 !important;
}

.color-light {
  color: #ffffff !important;
}

.color-purple {
  color: #7678ed !important;
}

.color-orange {
  color: #f18701 !important;
}

.color-orange-dark {
  color: #f35b04 !important;
}

/* font button CTA size */
.text-button-size {
  font-size: 20px !important;
  font-weight: 500 !important;
}

/* FONT SIZE  */

.text {
  font-size: 18px !important;
}

.text-20 {
  font-size: 20px !important;
}

@media (max-width: 900px) {
  .text-20 {
    font-size: 18px !important;
  }
}
@media (max-width: 600px) {
  .text-20 {
    font-size: 16px !important;
  }
}

.logo {
  width: 250px;
  height: 65px;
  margin-left: -30px;
  background: url(../../assets/img/logo-svg-legal-farm/LegalFarm-Logo_with_Lexia_positive.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.logo-mobile {
  width: 150px;
  height: 65px;
  margin-top: -20px;
  background: url(../../assets/img/logo-svg-legal-farm/LegalFarm-Logo_with_Lexia_positive.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

@media (max-width: 450px) {
  .logo-mobile {
    width: 100px;
    height: 50px;
    margin-top: -20px;
    background: url(../../assets/img/logo-svg-legal-farm/LegalFarm-Logo_with_Lexia_positive.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
}

/* tidio  */

#button-body {
  display: none !important;
}
