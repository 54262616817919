.container-crypto {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.container-content-crypto-one {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  margin-bottom: 50px !important;
}

.title-crypto {
  font-size: 50px !important;
  font-weight: 600 !important;
  margin-bottom: 50px !important;
  text-align: center !important;
}

.title-two-crypto {
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
}

.text-crypto {
  font-size: 18px !important;
}

.container-list-crypto {
  display: flex !important;
  justify-content: center !important;
  margin-top: 30px;
  margin-right: 40px;
}

.logo-cryptosmart {
  background: url(../../img/Logo/logo-cryptosmart.png);
  width: 80px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 30px;
}

@media (max-width: 900px) {
  .container-crypto {
    height: 100%;
    display: flex;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .title-crypto {
    font-size: 48px !important;
    margin-bottom: 70px !important;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .container-crypto {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .text-crypto {
    font-size: 16px !important;
  }
  .title-crypto {
    font-size: 32px !important;
    margin-bottom: 70px !important;
  }
  .container-list-crypto {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100%;
  }
}
