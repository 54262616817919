.container-TechSolution {
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.title-TechSolution {
  font-size: 50px !important;
  font-weight: 600 !important;
  margin-bottom: 50px !important;
  text-align: center !important;
}

.description-TechSolution {
  font-size: 20px !important;
  text-align: center !important;
}

.container-button-TechSolution {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.button-cta-TechSolution {
  border-radius: 5px;
  width: 280px;
  text-align: center;
  padding: 16px !important;
  transition: background-color 0.5s ease;
  border: 2px solid #f6f6f6 !important;
  background-color: transparent;
}

.button-cta-TechSolution:hover {
  background-color: #bfbdbd47;
}

@media (max-width: 900px) {
  .title-TechSolution {
    font-size: 48px !important;
  }
  .description-TechSolution {
    font-size: 18px !important;
  }
  .container-TechSolution {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (max-width: 600px) {
  .title-TechSolution {
    font-size: 32px !important;
  }
}
