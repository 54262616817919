.container-Price-company {
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
  height: 100%;
}

.title-Price-company {
  font-size: 50px !important;
  font-weight: 600 !important;
  margin-bottom: 20px !important;
}

.title-Price-description-company {
  font-size: 50px !important;
  margin-bottom: 50px !important;
}

.text-info-costs-notary {
  font-size: 12px !important;
}

.container-subTitle-cardPrice-company {
  width: 85% !important;
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto !important;
  flex-direction: column !important;
}

.container-card-Price-company {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.box-text-card-Price-company {
  display: flex !important;
  align-items: center !important;
  margin-top: 110px !important;
  flex-direction: column !important;
}

.title-card-company {
  font-size: 24px !important;
  font-weight: bold !important;
}
.text-price-company {
  font-size: 50px !important;
  font-weight: 500 !important;
  margin-top: 30px !important;
}
.text-info-price-company {
  font-size: 10px !important;
}
.text-card-company {
  font-size: 14px !important;
}

.icon-list-card {
  text-align: center !important;
  width: 25px;
  height: 25px;
  background: url(../../img/Icons/Add.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.Card-company {
  border-radius: 5px;
  padding: 40px 20px 40px 20px;
  text-align: center;
}

.container-button-Price-company {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.button-cta-Price-company {
  border-radius: 5px;
  width: 280px;
  text-align: center;
  padding: 16px !important;
  transition: background-color 0.5s ease;
  border: 2px solid #f6f6f6 !important;
  background-color: transparent;
}

.button-cta-Price-company:hover {
  background-color: #bfbdbd47;
}

@media (max-width: 900px) {
  .title-Price-company {
    font-size: 48px !important;
    margin-bottom: 20px !important;
    text-align: center;
  }

  .title-Price-description-company {
    font-size: 48px !important;
    margin-bottom: 50px !important;
    text-align: center;
  }

  .container-Price-company {
    padding-top: 90px;
    padding-bottom: 70px;
  }
  .box-text-card-Price-company {
    margin-top: 50px !important;
    text-align: center !important;
  }
}

@media (max-width: 600px) {
  .title-Price-company {
    font-size: 32px !important;
    margin-bottom: 20px !important;
  }

  .title-Price-description-company {
    font-size: 32px !important;
    margin-bottom: 40px !important;
  }

  .container-card-Price-company {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
