.container-card-services-digitalSignature {
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 100px;
}

.title-card-services-digitalSignature {
  font-size: 50px !important;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: 50px !important;
}

.container-interno-HowActiveDigitalSignature {
  display: flex;
  justify-content: center;
}

.box-card-service-digitalSignature {
  height: 100%;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px !important;
  display: flex;
  justify-content: center;
}

.box-text {
  margin-left: 30px !important;
  width: 70% !important;
  margin-top: -5px !important;
}

.card-services-digitalSignature {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 30px 15px 30px 15px;
  /* max-width: 300px !important; */
  flex-direction: column;
  min-height: 450px;
}

.title-services-digitalSignature {
  font-size: 24px !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
}

.bubbles-container {
  display: flex !important;
  flex-direction: column !important;
  /* align-items: flex-start !important; */
  justify-content: center !important;
  /* margin-top: 50px !important; */
}

.bubble {
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  background-color: #ffffff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 60px !important;
  position: relative !important;
}
.bubble::before {
  content: "" !important;
  position: absolute !important;
  top: -35% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 80px !important;
  color: #ffffff !important;
}

.bubbles-container .first-bubble-signature::before {
  display: none !important;
}
.bubbles-container .second-bubble-signature::before {
  content: "" !important;
  position: absolute !important;
  top: -60% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 70px !important;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  border-style: dashed !important;
}
.bubbles-container .three-bubble-signature::before {
  content: "" !important;
  position: absolute !important;
  top: -60% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 70px !important;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  border-style: dashed !important;
}

.bubble-text {
  font-size: 32px !important;
  font-weight: 500 !important;
}

.description-bubble {
  font-size: 20px;
}

@media (max-width: 1200px) {
  .card-services-digitalSignature {
    min-height: 420px !important;
    margin-bottom: 50px !important;
  }
}

@media (max-width: 900px) {
  .description-bubble {
    font-size: 18px;
  }
}

@media (max-width: 720px) {
  .card-services-digitalSignature {
    min-height: 450px !important;
    margin-bottom: 50px !important;
  }
}

@media (max-width: 650px) {
  .card-services-digitalSignature {
    min-height: 440px !important;
    margin-bottom: 50px !important;
  }
}

@media (max-width: 600px) {
  .card-services-digitalSignature {
    min-height: 420px !important;
    margin-bottom: 50px !important;
  }
  .title-card-services-digitalSignature {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
  .container-card-services-digitalSignature {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .description-bubble {
    font-size: 16px;
  }
  .box-card-service-digitalSignature {
    margin-top: 0px !important;
  }
}

@media (max-width: 400px) {
  .container-interno-HowActiveDigitalSignature {
    margin-bottom: 20px !important;
  }
  .bubbles-container .second-bubble-signature::before {
    top: -60% !important;
    height: 85px !important;
  }
  .bubbles-container .three-bubble-signature::before {
    top: -60% !important;
    height: 85px !important;
  }
}

@media (max-width: 400px) {
  .bubbles-container .second-bubble-signature::before {
    top: -75% !important;
    height: 100px !important;
  }
  .bubbles-container .three-bubble-signature::before {
    top: -60% !important;
    height: 85px !important;
  }
}
