.container-aboutUs-sectionWant {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.title-aboutUs-sectionWant {
  font-size: 60px !important;
  font-weight: bold !important;
}

.description-aboutUs-sectionWant {
  margin-top: 30px !important;
}

@media (max-width: 900px) {
  .title-aboutUs-sectionWant {
    font-size: 48px !important;
    font-weight: bold !important;
  }
}

@media (max-width: 600px) {
  .container-aboutUs-sectionWant {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .title-aboutUs-sectionWant {
    font-size: 38px !important;
    font-weight: bold !important;
  }
}

@media (max-width: 450px) {
  .title-aboutUs-sectionWant {
    font-size: 32px !important;
    font-weight: bold !important;
  }
}
