.container-header-faq {
  height: 100%;
  margin-top: 100px;
  margin-bottom: 100px !important;
}

.title-header-faq {
  font-size: 60px !important;
  font-weight: bold !important;
  line-height: 1.1 !important;
}

.container-card-faq {
  margin-top: 90px !important;
  margin-bottom: 30px !important;
  display: flex !important;
  justify-content: center !important;
}

.card-shareTransfer-faq {
  display: flex;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.card-srl-faq {
  display: flex;
  padding: 20px;
  border-radius: 5px;
  justify-content: center;
  margin-right: 50px !important;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  text-align: center !important;
}

.card-digitalSignature-faq {
  display: flex;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  margin-left: 50px !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.card-trademark-faq {
  display: flex;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  margin-left: 50px !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

@media (max-width: 1050px) {
  .container-header-faq {
    height: 100%;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

@media (max-width: 900px) {
  .container-header-faq {
    margin-top: 120px;
  }
  .title-header-faq {
    font-size: 48px !important;
    font-weight: bold !important;
    line-height: 1.1 !important;
  }
  .description-hero {
    margin-top: 30px !important;
    line-height: 1.8 !important;
  }

  .card-srl-faq {
    margin-left: 0px !important;
    margin-bottom: 50px !important;
  }

  .card-shareTransfer-faq {
    margin-left: 0px !important;
    margin-bottom: 50px !important;
  }

  .card-digitalSignature-faq {
    margin-left: 50px !important;
    max-height: none !important;
  }

  .card-trademark-faq {
    margin-left: 0px !important;
    max-height: none !important;
  }
}

@media (max-width: 600px) {
  .container-header-faq {
    margin-top: 80px;
    margin-bottom: 60px !important;
  }
  .title-header-faq {
    font-size: 38px !important;
    font-weight: bold !important;
    line-height: 1.1 !important;
  }
  .card-srl-faq {
    margin-right: 0px !important;
    margin-bottom: 30px !important;
  }
  .card-shareTransfer-faq {
    margin-left: 0px !important;
    margin-bottom: 30px !important;
  }
  .card-digitalSignature-faq {
    margin-left: 0px !important;
    max-height: 100% !important;
    margin-bottom: 30px !important;
  }
  .card-trademark-faq {
    margin-left: 0px !important;
    margin-bottom: 30px !important;
    max-height: 100% !important;
  }
}

@media (max-width: 470px) {
  .title-header-faq {
    font-size: 32px !important;
    font-weight: bold !important;
    line-height: 1.1 !important;
  }

  .card-digitalSignature-faq {
    max-height: none !important;
  }

  .card-trademark-faq {
    max-height: none !important;
  }
}
