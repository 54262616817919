.container-TheyChoseUs {
  display: flex;
  /* justify-content: center; */
  padding-top: 120px;
  padding-bottom: 120px;
  height: 100%;
}

.container-card-TheyChoose {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.card-TheyChooseUs {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  height: 335px !important;
  padding-top: 20px;
  padding-bottom: 20px;
}

.title-TheyChoseUs {
  font-size: 50px !important;
  font-weight: 600 !important;
  margin-bottom: 70px !important;
}

.text-card-TheyChoose {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px !important;
  text-align: center;
  font-style: italic !important;
}

.text-info-theyChoose {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-size: 14px !important;
}

.icon-review {
  text-align: center !important;
  width: 50px;
  height: 50px;
  background: url(../../img/Icons/Review.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-woman {
  text-align: center !important;
  width: 30px;
  height: 30px;
  background: url(../../img/Icons/UserFemale.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-man {
  text-align: center !important;
  width: 30px;
  height: 30px;
  background: url(../../img/Icons/UserMale.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.container-logo-trustpilot-TheyChoose {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
}

.logo-trustpilot-TheyChoose {
  width: 180px;
  height: 180px;
  background: url(../../img/Trustpilot-logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1350px) {
  .card-TheyChooseUs {
    height: 350px !important;
  }
}

@media (max-width: 1080px) {
  .card-TheyChooseUs {
    height: 400px !important;
  }
}

@media (max-width: 980px) {
  .card-TheyChooseUs {
    height: 450px !important;
  }
}

@media (max-width: 900px) {
  .title-TheyChoseUs {
    font-size: 48px !important;
    margin-bottom: 70px !important;
  }

  .card-TheyChooseUs {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    height: 300px !important;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 50px !important;
  }
  .container-TheyChoseUs {
    padding-top: 90px;
    padding-bottom: 50px;
  }
  .container-logo-trustpilot-TheyChoose {
    margin-top: -70px !important;
    margin-bottom: 20px !important;
  }
}

@media (max-width: 750px) {
  .card-TheyChooseUs {
    height: 350px !important;
  }
}

@media (max-width: 600px) {
  .title-TheyChoseUs {
    font-size: 32px !important;
    margin-bottom: 70px !important;
  }
  .card-TheyChooseUs {
    height: 350px !important;
  }
  .container-card-TheyChoose {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media (max-width: 460px) {
  .card-TheyChooseUs {
    height: 350px !important;
  }
}
