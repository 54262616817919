.container-Faq {
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
  padding-left: 100px;
  padding-right: 100px;
}

.title-Faq {
  font-size: 50px !important;
  font-weight: 600 !important;
  margin-bottom: 50px !important;
  text-align: center !important;
}

.close-modal-faq:hover {
  background-color: transparent !important;
}

.text-accordion-faq {
  font-size: 16px !important;
}

.accordion-details-faq {
  padding: 0px 30px 20px 30px !important;
}

.container-accordion-faq {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  margin-top: 50px !important;
}

@media (max-width: 900px) {
  .title-Faq {
    font-size: 48px !important;
  }
  .container-Faq {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .container-accordion-faq {
    margin-top: 20px !important;
  }
}

@media (max-width: 600px) {
  .title-Faq {
    font-size: 32px !important;
  }
  .container-Faq {
    padding-left: 0px;
    padding-right: 0px;
  }
  .text-accordion-faq {
    font-size: 14px !important;
  }
  .accordion-details-faq {
    padding: 0px 30px 20px 30px !important;
  }
}
