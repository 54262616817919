.container-AdvantagesCompany {
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
}

.title-AdvantagesCompany {
  font-size: 50px !important;
  font-weight: 600 !important;
  margin-bottom: 50px !important;
}

.container-AdvantagesCompany-content {
  display: flex;
  margin-left: 100px !important;
}

.icon-vat {
  background: url(../../img/Icons/vat.svg);
  width: 55px;
  height: 55px;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-team {
  background: url(../../img/Icons/Team.svg);
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-easy {
  background: url(../../img/Icons/Easy.svg);
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.description-AdvantagesCompany {
  font-size: 20px !important;
  margin-left: 10px !important;
}

.container-text-AdvantagesCompany {
  margin-bottom: 30px !important;
}

.container-img-AdvantagesCompany {
  margin-left: 100px !important;
  margin-top: -50px !important;
}

.img-AdvantagesCompany {
  width: 500px;
  height: 450px;
  background-image: url(../../img/graphic/srl-grafica.svg) !important;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1350px) {
  .container-img-AdvantagesCompany {
    margin-left: 0px !important;
    margin-top: -50px !important;
  }
}

@media (max-width: 1180px) {
  .container-img-AdvantagesCompany {
    margin-left: 0px !important;
    margin-top: 50px !important;
  }
  .img-AdvantagesCompany {
    width: 400px;
    height: 450px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1090px) {
  .container-img-AdvantagesCompany {
    margin-left: 0px !important;
    margin-top: 50px !important;
  }
  .img-AdvantagesCompany {
    width: 400px;
    height: 450px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .description-whoChooseUs {
    margin-left: 20px !important;
  }
}

@media (max-width: 1000px) {
  .container-img-AdvantagesCompany {
    margin-left: 10px !important;
    margin-top: 50px !important;
  }
  .img-AdvantagesCompany {
    width: 360px;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 950px) {
  .container-img-AdvantagesCompany {
    margin-left: 10px !important;
    margin-top: 100px !important;
  }
  .img-AdvantagesCompany {
    width: 320px;
    height: 350px;

    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 900px) {
  .container-AdvantagesCompany {
    display: flex;
    padding-top: 0px;
    padding-bottom: 80px;
  }

  .container-img-AdvantagesCompany {
    display: flex;
    margin-left: 0px !important;
    justify-content: center;
    margin-top: 50px !important;
  }

  .container-AdvantagesCompany-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .container-AdvantagesCompany-content {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-left: 0px !important;
  }

  .img-AdvantagesCompany {
    width: 500px;
    height: 350px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .title-AdvantagesCompany {
    font-size: 48px !important;
    font-weight: 600 !important;
    margin-bottom: 50px !important;
  }
  .description-AdvantagesCompany {
    font-size: 18px !important;
    margin-left: 10px !important;
  }
}

@media (max-width: 700px) {
  .description-AdvantagesCompany {
    font-size: 18px !important;
    margin-left: 20px !important;
  }
}

@media (max-width: 600px) {
  .title-AdvantagesCompany {
    font-size: 38px !important;
    font-weight: 600 !important;
    margin-bottom: 50px !important;
  }
  .title-AdvantagesCompany {
    text-align: center !important;
    font-size: 38px !important;
  }
  .description-AdvantagesCompany {
    font-size: 16px !important;
    margin-left: 40px !important;
  }
}

@media (max-width: 800px) {
  .icon-vat {
    width: 45px;
    height: 45px;
  }

  .icon-team {
    width: 45px;
    height: 45px;
  }

  .icon-easy {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 600px) {
  .icon-vat {
    /* margin-left: -20px; */
  }

  .icon-team {
    /* margin-left: -20px; */
  }

  .icon-easy {
    /* margin-left: -20px; */
  }
}
