.container-Price-trademark {
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
  height: 100%;
}

.title-Price-trademark {
  font-size: 50px !important;
  font-weight: 600 !important;
  margin-bottom: 70px !important;
}

.container-card-Price-trademark {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.box-text-card-Price-trademark {
  display: flex !important;
  align-items: center !important;
  margin-top: 200px !important;
  flex-direction: column !important;
}

.title-card-trademark {
  font-size: 24px !important;
  font-weight: bold !important;
}
.text-price-trademark {
  font-size: 50px !important;
  font-weight: 500 !important;
  margin-top: 30px !important;
}
.text-info-price-trademark {
  font-size: 12px !important;
}
.text-info-price-trademark-iva {
  font-size: 10px !important;
}
.text-card-trademark {
  font-size: 14px !important;
}

.icon-list-card {
  text-align: center !important;
  width: 25px;
  height: 25px;
  background: url(../../img/Icons/Add.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.Card-trademark {
  border-radius: 5px;
  padding: 40px 20px 40px 20px;
  text-align: center;
}

.container-button-Price-trademark {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}

.button-cta-Price-trademark {
  border-radius: 5px;
  width: 280px;
  text-align: center;
  padding: 16px !important;
  transition: background-color 0.5s ease;
  border: 2px solid #f6f6f6 !important;
  background-color: transparent;
}

.button-cta-Price-trademark:hover {
  background-color: #bfbdbd47;
}

.modal-container-faq-trademark {
  position: absolute !important;
  width: 80% !important;
  top: 100px !important;
  left: 50% !important;
  transform: translate(-50%, 50%) !important;
}

@media (max-width: 900px) {
  .title-Price-trademark {
    font-size: 48px !important;
    margin-bottom: 70px !important;
  }

  .container-Price-trademark {
    padding-top: 90px;
    padding-bottom: 0px;
  }
  .container-logo-trustpilot-TheyChoose-trademark {
    margin-top: -70px !important;
    margin-bottom: 20px !important;
  }
  .box-text-card-Price-trademark {
    margin-top: 50px !important;
    text-align: center !important;
  }
  .Card-trademark {
    margin-bottom: 50px;
  }
  .container-button-Price-trademark {
    margin-top: 20px;
    margin-bottom: 100px;
  }
}

@media (max-width: 600px) {
  .title-Price-trademark {
    font-size: 32px !important;
    margin-bottom: 70px !important;
  }

  .container-card-Price-trademark {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .modal-container-faq-trademark {
    top: -20px !important;
  }
}

@media (max-width: 400px) {
  .modal-container-faq-trademark {
    top: -80px !important;
  }
}
