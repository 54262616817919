.container-Blog {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 90px;
}

.container-card-blog {
  display: flex;
  justify-content: center;
  padding-top: 90px;
  padding-bottom: 70px;
}

.suggestions-link {
  color: #001f3f !important;
}

.button-searchbar:hover {
  background-color: transparent !important;
}

.input-searchbar-blog-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  background-color: #fff !important;
  color: #001f3f !important;
  border-radius: 4px;
  padding: 6px 0px 5px 8px;
  border: 1px solid #001f3f !important;
}

.input-width-search {
  border: none;
  width: 88%;
  outline: none;
  box-shadow: none;
}

.input-searchbar-blog {
  width: 15%;
  border: none;
  outline: none;
  box-shadow: none;
}

.icon-search-blog {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.icon-search-blog:hover {
  background-color: transparent !important;
}

.icon-search-reset {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.icon-search-reset:hover {
  background-color: transparent !important;
}

@media (max-width: 600px) {
}

@media (max-width: 530px) {
  .input-width-search {
    width: 80%;
  }
  .input-searchbar-blog {
    width: 20%;
    margin-left: 20px !important;
  }
}

@media (max-width: 400px) {
  .input-width-search {
    width: 75%;
  }
  .input-searchbar-blog {
    width: 15%;
    margin-left: 20px !important;
  }
}

.img-blog {
  background: url(../../img/Logo/Logo-Lexia.png);
  width: 100%;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
}

.title-Blog {
  font-size: 60px !important;
  font-weight: bold !important;
}

@media (max-width: 900px) {
  .title-Blog {
    font-size: 48px !important;
    font-weight: bold !important;
  }
}

@media (max-width: 600px) {
  .container-Blog {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .title-Blog {
    font-size: 38px !important;
    font-weight: bold !important;
  }
  .container-card-blog {
    padding-top: 80px;
    padding-bottom: 30px;
  }
}

@media (max-width: 450px) {
  .title-Blog {
    font-size: 32px !important;
    font-weight: bold !important;
  }
}
