.container-hero-digitalSignature {
  height: 100%;
  margin-top: 150px;
  margin-bottom: 100px !important;
}

.box-hero-content-digitalSignature {
  display: flex;
  margin-left: 100px !important;
}

.title-hero-digitalSignature {
  font-size: 60px !important;
  font-weight: bold !important;
  line-height: 1.1 !important;
}

.description-hero-digitalSignature {
  margin-top: 50px !important;
  line-height: 1.8 !important;
}

.container-button-digitalSignature {
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
}

.img-hero-digitalSignature {
  background: url("../../img/Risorsa.svg");
  width: 100%;
  height: 350px;
  margin-top: -20px !important;
  background-size: cover;
  background-position: center;
}

.button-cta-digitalSignature {
  border-radius: 5px;
  width: 280px;
  text-align: center;
  padding: 16px !important;
  transition: background-color 0.5s ease;
}

.container-animation-hero-digitalSignature {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.animation-hero-digitalSignature {
  height: 120%;
  width: 100%;
  margin-top: -110px;
  margin-right: -30px;
  z-index: 0 !important;
}

@media (max-width: 1350px) {
  .animation-hero-digitalSignature {
    height: 150%;
    width: 120%;
    margin-top: -110px;
    margin-right: -60px;
  }
}

@media (max-width: 1050px) {
  .container-hero-digitalSignature {
    height: 100%;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .animation-hero-digitalSignature {
    height: 150%;
    width: 130%;
    margin-top: -110px;
    margin-right: -20px !important;
  }
}

@media (max-width: 900px) {
  .container-hero-digitalSignature {
    margin-top: 0px;
  }
  .box-hero-content-digitalSignature {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
    margin-left: 0px !important;
  }
  .container-content-digitalSignature {
    margin-top: 20px;
    margin-bottom: 100px;
  }
  .container-animation-hero-digitalSignature {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .animation-hero-digitalSignature {
    height: 400px;
    width: 400px;
    margin-top: 0px;
    margin-left: 0px;
  }
  .title-hero-digitalSignature {
    font-size: 48px !important;
    font-weight: bold !important;
    line-height: 1.1 !important;
  }
  .description-hero-digitalSignature {
    margin-top: 30px !important;
    line-height: 1.8 !important;
  }
  .container-button-digitalSignature {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .container-hero-digitalSignature {
    margin-bottom: 80px !important;
  }
  .box-hero-content-digitalSignature {
    text-align: center;
  }
  .container-button-digitalSignature {
    display: flex;
    justify-content: center;
  }
  .title-hero-digitalSignature {
    font-size: 38px !important;
    font-weight: bold !important;
    line-height: 1.1 !important;
  }
  .animation-hero-digitalSignature {
    height: 300px;
    width: 285px;
  }
}

@media (max-width: 450px) {
  .title-hero-digitalSignature {
    font-size: 32px !important;
    font-weight: bold !important;
    line-height: 1.1 !important;
  }
}

@media (min-width: 1600px) {
  .animation-hero-digitalSignature {
    height: 450px;
    width: 450px;
    margin-top: -100px;
    margin-right: -40px;
  }
}
