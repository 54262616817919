.container-metrics-company {
  height: 100%;
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
}

.container-content-metrics-company {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.card-metrics-company {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-change {
  background: url(../../img/Icons/iconsMetrics/Change.svg);
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}

.icon-online {
  background: url(../../img/Icons/iconsMetrics/Online.svg);
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}

.icon-prize {
  background: url(../../img/Icons/iconsMetrics/Prize.svg);
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}

.text-metrics-company {
  font-size: 18px !important;
}

@media (max-width: 900px) {
  .container-metrics-company {
    height: 100%;
    display: flex;
    padding-top: 20px;
    padding-bottom: 80px;
  }
  .container-content-metrics-company {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .card-metrics-company {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px !important;
  }
}

@media (max-width: 600px) {
  .icon-change {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }

  .icon-online {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }

  .icon-prize {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  .text-metrics {
    font-size: 16px !important;
  }
}
