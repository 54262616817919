.container-aboutUs-sectionLexia {
  height: 100%;
  display: flex !important;
  justify-content: center !important;
  padding-top: 120px;
  padding-bottom: 120px;
}

.title-aboutUs-sectionLexia {
  font-size: 50px !important;
  font-weight: bold !important;
}

.description-aboutUs-sectionLexia {
  margin-top: 30px !important;
}

.Logo-Lexia-AboutUs {
  background: url(../../img/Logo/Logo-Lexia.png);
  width: 200px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 900px) {
  .title-aboutUs-sectionLexia {
    font-size: 48px !important;
    font-weight: bold !important;
  }
}

@media (max-width: 600px) {
  .container-aboutUs-sectionLexia {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .title-aboutUs-sectionLexia {
    font-size: 32px !important;
    font-weight: bold !important;
  }
}

@media (max-width: 450px) {
  .title-aboutUs-sectionLexia {
    font-size: 32px !important;
    font-weight: bold !important;
  }
}
