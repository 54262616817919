.container-cta-final {
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.title-cta-final {
  font-size: 50px !important;
  font-weight: 600 !important;
  margin-bottom: 50px !important;
  text-align: center !important;
}

.title-contact {
  font-size: 32px !important;
  font-weight: 600 !important;
  margin-top: 100px !important;
  margin-bottom: 20px !important;
  text-align: center !important;
}

.description-contact {
  font-size: 20px !important;
  text-align: center !important;
}

.container-button-cta-final {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.container-button-contact {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.button-cta-final {
  border-radius: 5px;
  width: 280px;
  text-align: center;
  padding: 16px !important;
  transition: background-color 0.5s ease;
  border: 2px solid #001f3f !important;
  background-color: transparent;
}

.button-cta-final-blog {
  border-radius: 5px;
  width: 280px;
  color: #001f3f;
  text-align: center;
  padding: 16px !important;
  transition: background-color 0.5s ease;
  border: 2px solid #fff !important;
  background-color: transparent;
}

.button-cta-final-blog:hover {
  background-color: #bfbdbd47;
}

/* .button-contact {
  border-radius: 5px;
  width: 180px;
  text-align: center;
  padding: 8px !important;
  transition: background-color 0.5s ease;
  border: 2px solid #001f3f !important;
  background-color: transparent;
} */

.button-cta-final:hover {
  background-color: #bfbdbd47;
}

@media (max-width: 900px) {
  .title-cta-final {
    font-size: 48px !important;
  }
  .title-contact {
    font-size: 28px !important;
    font-weight: 600 !important;
    margin-top: 100px !important;
    margin-bottom: 20px !important;
    text-align: center !important;
  }
  .description-contact {
    font-size: 18px !important;
  }
  .container-cta-final {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (max-width: 600px) {
  .title-cta-final {
    font-size: 32px !important;
  }
  .title-contact {
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-top: 100px !important;
    margin-bottom: 20px !important;
    text-align: center !important;
  }
  .description-contact {
    font-size: 14px !important;
  }
}
