.container-FaqCompany {
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
  padding-left: 100px;
  padding-right: 100px;
}

.container-accordion-faq-Company {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  margin-top: 50px !important;
}

.title-FaqCompany {
  font-size: 50px !important;
  font-weight: 600 !important;
  margin-bottom: 50px !important;
  text-align: center !important;
}

.description-FaqCompany {
  font-size: 20px !important;
  text-align: center !important;
}

.container-button-FaqCompany {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.button-cta-FaqCompany {
  border-radius: 5px;
  width: 280px;
  text-align: center;
  padding: 16px !important;
  transition: background-color 0.5s ease;
  border: 2px solid #f6f6f6 !important;
  background-color: transparent;
}

.button-cta-FaqCompany:hover {
  background-color: #bfbdbd47;
}

.container-accordion-faq-DigitalSignature {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  margin-top: 50px !important;
}

@media (max-width: 900px) {
  .title-FaqCompany {
    font-size: 48px !important;
  }
  .description-FaqCompany {
    font-size: 18px !important;
  }
  .container-FaqCompany {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .container-accordion-faq-DigitalSignature {
    margin-top: 20px !important;
  }
  .container-accordion-faq-digitalSignature {
    margin-top: 20px !important;
  }
}

@media (max-width: 600px) {
  .title-FaqCompany {
    font-size: 32px !important;
  }
  .container-FaqCompany {
    padding-left: 0px;
    padding-right: 0px;
  }
}
