.content p {
  margin: 0px !important;
}

.title-article-blog {
  font-size: 50px !important;
  font-weight: 600 !important;
  text-align: center;
  text-align: center;
  margin-bottom: 50px !important;
  margin-top: 50px !important;
}

.container-img-article-blog {
  width: 80%;
  height: 500px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.img-article-blog {
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center;
}

.container-button-article-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.container-previous-article-icon {
  display: none !important;
}

.container-next-article-icon {
  display: none !important;
}

.text-all-article {
  color: #f7b801 !important;
}

.icon-previous {
  font-size: 16px !important;
  margin-right: 4px !important;
  border-bottom: 1px solid #f7b801 !important;
}

.icon-next {
  font-size: 16px !important;
  margin-left: 4px !important;
  border-bottom: 1px solid #f7b801 !important;
}

@media (max-width: 800px) {
  .container-img-article-blog {
    width: 80%;
    height: 300px;
  }

  .img-article-blog {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
  }
}

@media (max-width: 800px) {
  .container-previous-article {
    display: none !important;
  }

  .container-previous-article-icon {
    display: block !important;
  }

  .container-next-article {
    display: none !important;
  }

  .container-next-article-icon {
    display: block !important;
  }

  .text-all-article {
    font-size: 16px !important;
  }

  .container-button-article-details {
    margin-top: 0px !important;
  }

  .icon-previous {
    font-size: 24px !important;
    margin-right: 4px !important;
  }

  .icon-next {
    font-size: 24px !important;
    margin-left: 4px !important;
  }
}

@media (max-width: 600px) {
  .title-article-blog {
    font-size: 32px !important;
    font-weight: 600 !important;
  }

  .container-img-article-blog {
    width: 80%;
    height: 200px;
  }

  .img-article-blog {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
  }
}
