.container-hero {
  height: 100%;
  margin-top: 150px;
  margin-bottom: 100px !important;
}

.box-hero-content {
  display: flex;
  margin-left: 100px !important;
}

.title-hero {
  font-size: 60px !important;
  font-weight: bold !important;
  line-height: 1.1 !important;
}

.description-hero {
  margin-top: 50px !important;
  line-height: 1.8 !important;
}

.container-button {
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
}

.img-hero {
  background: url("../../img/Risorsa.svg");
  width: 100%;
  height: 350px;
  margin-top: -20px !important;
  background-size: cover;
  background-position: center;
}

.button-cta {
  border-radius: 5px;
  width: 280px;
  text-align: center;
  padding: 16px !important;
  transition: background-color 0.5s ease;
}

.container-animation-hero {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.animation-hero {
  height: 150%;
  width: 100%;
  margin-top: -110px;
  margin-left: -150px;
  z-index: 0 !important;
}

@media (max-width: 1350px) {
  .animation-hero {
    height: 150%;
    width: 120%;
    margin-top: -110px;
    margin-right: -60px;
  }
}

@media (max-width: 1050px) {
  .container-hero {
    height: 100%;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .animation-hero {
    height: 150%;
    width: 130%;
    margin-top: -110px;
    margin-right: -20px !important;
  }
}

@media (max-width: 900px) {
  .container-hero {
    margin-top: 0px;
  }
  .box-hero-content {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-left: 0px !important;
  }
  .container-content {
    margin-top: 20px;
    margin-bottom: 100px;
  }
  .container-animation-hero {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .animation-hero {
    height: 500px;
    width: 500px;
    margin-top: 0px;
    margin-left: 0px;
  }
  .title-hero {
    font-size: 48px !important;
    font-weight: bold !important;
    line-height: 1.1 !important;
  }
  .description-hero {
    margin-top: 30px !important;
    line-height: 1.8 !important;
  }
  .container-button {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
  }
}

@media (max-width: 600px) {
  .container-hero {
    margin-bottom: 80px !important;
  }
  .box-hero-content {
    text-align: center;
  }
  .container-button {
    display: flex;
    justify-content: center;
  }
  .title-hero {
    font-size: 38px !important;
    font-weight: bold !important;
    line-height: 1.1 !important;
  }
  .animation-hero {
    height: 300px;
    width: 285px;
  }
}

@media (max-width: 450px) {
  .title-hero {
    font-size: 32px !important;
    font-weight: bold !important;
    line-height: 1.1 !important;
  }
}

@media (min-width: 2000px) {
  .animation-hero {
    height: 550px;
    width: 550px;
    margin-top: -150px;
    margin-right: -40px;
  }
}
