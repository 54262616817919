.container-whoChooseUs {
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
}

.title-whoChooseUs {
  font-size: 50px !important;
  font-weight: 600 !important;
  margin-bottom: 50px !important;
}

.container-whyChoose-content {
  display: flex;
  margin-left: 100px !important;
}

.icon-coin {
  background: url(../../img/Icons/Savings.svg);
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}
.icon-time {
  background: url(../../img/Icons/Time.svg);
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}
.icon-home-whyChoose {
  background: url(../../img/Icons/Home-light.svg);
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.description-whoChooseUs {
  font-size: 20px !important;
  margin-left: 10px !important;
}

.container-text-whyChooseUs {
  margin-bottom: 30px !important;
}

.container-img-whyChooseUs {
  margin-left: 80px !important;
  margin-top: -50px !important;
}

.img-whyChooseUs {
  width: 500px;
  height: 500px;
  background-image: url(../../img/graphic/PerchèSceglierci.svg) !important;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1350px) {
  .container-img-whyChooseUs {
    margin-left: 0px !important;
    margin-top: -50px !important;
  }
}

@media (max-width: 1180px) {
  .container-img-whyChooseUs {
    margin-left: 0px !important;
    margin-top: 50px !important;
  }
  .img-whyChooseUs {
    width: 400px;
    height: 450px;
    background-image: url(../../img/graphic/PerchèSceglierci.svg) !important;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1090px) {
  .container-img-whyChooseUs {
    margin-left: -50px !important;
    margin-top: 50px !important;
  }
  .img-whyChooseUs {
    width: 400px;
    height: 450px;
    background-image: url(../../img/graphic/PerchèSceglierci.svg) !important;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .description-whoChooseUs {
    margin-left: 20px !important;
  }
}

@media (max-width: 1000px) {
  .container-img-whyChooseUs {
    margin-left: -10px !important;
    margin-top: 50px !important;
  }
  .img-whyChooseUs {
    width: 360px;
    height: 400px;
    background-image: url(../../img/graphic/PerchèSceglierci.svg) !important;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 950px) {
  .container-img-whyChooseUs {
    margin-left: -10px !important;
    margin-top: 100px !important;
  }
  .img-whyChooseUs {
    width: 320px;
    height: 350px;
    background-image: url(../../img/graphic/PerchèSceglierci.svg) !important;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 900px) {
  .container-whoChooseUs {
    flex-direction: column-reverse !important;
    margin-top: -160px;
    padding-bottom: 80px;
  }

  .container-img-whyChooseUs {
    display: flex;
    margin-left: 0px !important;
    justify-content: center;
    margin-top: 50px !important;
  }

  .container-whyChoose-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .container-whyChoose-content {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-left: 0px !important;
  }

  .img-whyChooseUs {
    width: 500px;
    height: 350px;
    background-image: url(../../img/graphic/PerchèSceglierci.svg) !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .title-whoChooseUs {
    font-size: 48px !important;
    font-weight: 600 !important;
    margin-bottom: 50px !important;
  }
  .description-whoChooseUs {
    font-size: 18px !important;
    margin-left: 10px !important;
  }
}

@media (max-width: 700px) {
  .description-whoChooseUs {
    font-size: 18px !important;
    margin-left: 20px !important;
  }
}

@media (max-width: 600px) {
  .title-whoChooseUs {
    font-size: 38px !important;
    font-weight: 600 !important;
    margin-bottom: 50px !important;
  }
  .title-whoChooseUs {
    text-align: center !important;
    font-size: 38px !important;
  }
  .description-whoChooseUs {
    font-size: 16px !important;
    margin-left: 40px !important;
  }
}

@media (max-width: 800px) {
  .icon-coin {
    width: 45px;
    height: 45px;
  }
  .icon-time {
    width: 45px;
    height: 45px;
  }
  .icon-home-whyChoose {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 800px) {
  .icon-coin {
    margin-left: -20px;
  }
  .icon-time {
    margin-left: -20px;
  }
  .icon-home-whyChoose {
    margin-left: -20px;
  }
}
