.container-AdvantagesShareTransfer {
  display: flex;
  padding-top: 120px;
  padding-bottom: 100px;
}

.title-AdvantagesShareTransfer {
  font-size: 50px !important;
  font-weight: 600 !important;
  margin-bottom: 50px !important;
}

.container-AdvantagesShareTransfer-content {
  display: flex;
  margin-left: 100px !important;
}

.icon-automation {
  background: url(../../img/Icons/Automation.svg);
  width: 55px;
  height: 55px;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-pig {
  background: url(../../img/Icons/Savings.svg);
  width: 55px;
  height: 55px;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-easy-shareTransfer {
  background: url(../../img/Icons/Easy.svg);
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-trophy {
  background: url(../../img/Icons/trophy.svg);
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.description-AdvantagesShareTransfer {
  font-size: 20px !important;
  margin-left: 10px !important;
}

.container-text-AdvantagesShareTransfer {
  margin-bottom: 30px !important;
}

.container-img-AdvantagesShareTransfer {
  margin-left: 120px !important;
  margin-top: 50px !important;
}

.img-AdvantagesShareTransfer {
  width: 430px;
  height: 430px;
  background-image: url(../../img/graphic/Cessione-grafica.svg) !important;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1350px) {
  .container-img-AdvantagesShareTransfer {
    margin-left: 100px !important;
    margin-top: 50px !important;
  }
}

@media (max-width: 1180px) {
  .container-img-AdvantagesShareTransfer {
    margin-left: 0px !important;
    margin-top: 50px !important;
  }
  .img-AdvantagesShareTransfer {
    width: 350px !important;
    height: 350px !important;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 150px !important;
  }
}

@media (max-width: 1090px) {
  .container-img-AdvantagesShareTransfer {
    margin-left: 50px !important;
    margin-top: 50px !important;
  }
  .img-AdvantagesShareTransfer {
    width: 400px;
    height: 450px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .description-AdvantagesShareTransfer {
    margin-left: 20px !important;
  }
}

@media (max-width: 1000px) {
  .container-img-AdvantagesShareTransfer {
    margin-left: 10px !important;
    margin-top: 50px !important;
  }
  .img-AdvantagesShareTransfer {
    width: 360px;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 950px) {
  .container-img-AdvantagesShareTransfer {
    margin-left: 10px !important;
    margin-top: 100px !important;
  }
  .img-AdvantagesShareTransfer {
    width: 320px;
    height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 0px !important;
  }
}

@media (max-width: 900px) {
  .container-AdvantagesShareTransfer {
    display: flex;
    padding-top: 0px;
    padding-bottom: 80px;
  }

  .container-img-AdvantagesShareTransfer {
    display: flex;
    margin-left: 0px !important;
    justify-content: center;
    margin-top: 50px !important;
  }

  .container-AdvantagesShareTransfer-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .container-AdvantagesShareTransfer-content {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-left: 0px !important;
  }

  .img-AdvantagesShareTransfer {
    width: 500px;
    height: 350px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .title-AdvantagesShareTransfer {
    font-size: 48px !important;
    font-weight: 600 !important;
    margin-bottom: 50px !important;
  }
  .description-AdvantagesShareTransfer {
    font-size: 18px !important;
    margin-left: 10px !important;
  }
}

@media (max-width: 700px) {
  .description-AdvantagesShareTransfer {
    font-size: 18px !important;
    margin-left: 20px !important;
  }
}

@media (max-width: 600px) {
  .title-AdvantagesShareTransfer {
    font-size: 38px !important;
    font-weight: 600 !important;
    margin-bottom: 50px !important;
  }
  .title-AdvantagesShareTransfer {
    text-align: center !important;
    font-size: 38px !important;
  }
  .description-AdvantagesShareTransfer {
    font-size: 16px !important;
    margin-left: 40px !important;
  }
}

@media (max-width: 800px) {
  .icon-automation {
    width: 45px;
    height: 45px;
  }

  .icon-pig {
    width: 45px;
    height: 45px;
  }

  .icon-easy-shareTransfer {
    width: 45px;
    height: 45px;
  }

  .icon-trophy {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 400px) {
  .icon-easy-shareTransfer {
    margin-left: -10px;
  }
}
