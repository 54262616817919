.container-aboutUs-SectionOurValues {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.container-card-aboutUs-SectionOurValues {
  display: flex;
  margin-top: 0px !important;
  justify-content: space-around;
}

.title-card-SectionOurValues {
  text-align: center;
  font-size: 24px !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
}

.card-aboutUs-SectionOurValues {
  margin-right: 50px;
  margin-top: 50px !important;
  text-align: center;
}

.title-aboutUs-SectionOurValues {
  font-size: 60px !important;
  font-weight: bold !important;
}

@media (max-width: 900px) {
  .title-aboutUs-SectionOurValues {
    font-size: 48px !important;
    font-weight: bold !important;
  }
  .card-aboutUs-SectionOurValues {
    margin-right: 0px;
    margin-top: 20px !important;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .container-aboutUs-SectionOurValues {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .title-aboutUs-SectionOurValues {
    font-size: 38px !important;
    font-weight: bold !important;
  }
}

@media (max-width: 450px) {
  .title-aboutUs-SectionOurValues {
    font-size: 32px !important;
    font-weight: bold !important;
  }
}
