/* BannerCompany.css */
.icon-warning-banner-company {
  background: url(../../img/Icons/warning.svg);
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.container-icon-banner-company {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-left: 50px !important;
}

.container-copy-banner-company {
  display: flex !important;
  align-items: center !important;
  margin-right: 50px !important;
}

.text-banner-company {
  font-size: 16px;
}

@media (max-width: 1000px) {
  .container-icon-banner-company {
    margin-left: 20px !important;
  }

  .container-copy-banner-company {
    margin-right: 20px !important;
  }
}

@media (max-width: 900px) {
  .container-icon-banner-company {
    margin-left: 0px !important;
  }

  .container-copy-banner-company {
    margin-right: 20px !important;
  }
}

@media (max-width: 700px) {
  .icon-warning-banner-company {
    width: 40px;
    height: 40px;
  }
  .text-banner-company {
    font-size: 14px !important;
  }
  .container-icon-banner-company {
    justify-content: start !important;
  }
}

@media (max-width: 400px) {
  .container-copy-banner-company {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}


.fixed-bottom-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #f5f5f5;
}
