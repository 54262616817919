/* codice menu + card  */
.soluzioni-dropdown {
  overflow: hidden;
  transition: height 0.2s linear;
  z-index: 1;
}
.card-soluzioni {
  border-radius: 5px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}
.card-soluzioni:hover {
  transform: scale(1.06);
}

.soluzioni-dropdown-smart {
  overflow: hidden;
}
.custom-animation {
  animation-name: slideInRight;
  animation-duration: 0.5s;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.card-soluzioni-smart {
  border-radius: 5px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}
.card-soluzioni-smart:hover {
  transform: scale(1.1);
}
.card-risorse {
  border-radius: 5px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}
.card-risorse:hover {
  transform: scale(1.1);
}

/*  link navbar  */

.container-link {
  width: 100px;
  text-align: center;
  cursor: pointer;
}
.margin-link {
  margin-right: 30px;
}
.link {
  font-weight: 600 !important;
  font-size: 16px !important;
}
.icon-dropdown {
  margin-left: 5px;
  margin-top: 5px;
}
.icon-dropdown-smart {
  margin-left: 5px;
  margin-top: 5px;
}
.marginButton {
  margin-left: 50px;
}
.button-login {
  border-radius: 5px;
  font-size: 14px !important;
  width: 100px;
  text-align: center;
  padding: 5px;
  transition: background-color 0.5s ease;
}
.button-consulenza {
  border-radius: 5px;
  height: 31px !important;
  font-size: 14px !important;
  padding-left: 15px !important;
  padding-right: 20px !important;
  /* width: 180px !important; */
  text-align: center;
  padding: 5px;
  transition: background-color 0.5s ease;
  cursor: pointer !important;
}
.button-consulenza-smart {
  border-radius: 5px;
  height: 31px !important;
  font-size: 14px !important;
  padding-left: 15px !important;
  padding-right: 20px !important;
  width: 80% !important;
  text-align: center;
  padding: 5px;
  transition: background-color 0.5s ease;
  cursor: pointer !important;
}
.icon-phone {
  color: #fff !important;
  font-size: 20px !important;
}
.text-button-call {
  color: #fff !important;
  margin-left: 10px !important;
  margin-top: 1px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.text-button {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.link-soluzioni {
  text-decoration: none !important;
}
.title-card-soluzioni {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.text-info-soluzioni {
  font-size: 14px !important;
  font-weight: 400 !important;
}

/* codice stile per flag lingua OFF */
.flag {
  display: inline-block;
  width: 18px;
  height: 12px;
  margin-right: 20px;
}
.it-flag {
  width: 18px;
  height: 12px;
  background: url("../../img/flag_it.svg") center/cover no-repeat;
  cursor: pointer;
}
.en-flag {
  width: 18px;
  height: 12px;
  background: url("../../img/flag_en.svg") center/cover no-repeat;
  cursor: pointer;
  margin-right: 25px;
}
.icon-home {
  background: url(../../img/Icons/Home.svg);
  width: 35px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: -7px;
  margin-left: 20px;
}
.icon-home-active {
  background: url(../../img/Icons/Home-active.svg);
  width: 35px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: -7px;
  margin-left: 20px;
}
.icon-close-smart {
  background: url(../../img/Icons/Close.svg);
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 0px !important;
  margin-top: 0px !important;
}
.icon-back-smart {
  background: url(../../img/Icons/Back.svg);
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 0px !important;
  margin-top: 0px !important;
}

/* gestione margini navbar  */
@media (max-width: 1120px) {
  .margin-link {
    margin-right: 20px;
  }
}
@media (max-width: 1020px) {
  .margin-link {
    margin-right: 10px;
  }
  .marginButton {
    margin-left: 20px;
  }
}

/* gestione navbar smrart & pc  */
.navbar-smart {
  display: none !important;
}

@media (max-width: 900px) {
  .navbar-pc {
    display: none !important;
  }
  .navbar-smart {
    display: block !important;
    background-color: #fff !important;
    padding-top: 10px !important;
  }
}

/* custom button navbar open-close */
.navbar-toggler:focus {
  box-shadow: none !important;
  border: none !important;
}
.navbar-toggler {
  border: none !important;
}
.navbar-toggler-icon {
  background-image: none !important;
  margin-right: 10px;
}
.icon-hamburger {
  height: 3px !important;
  width: 25px !important;
  display: block !important;
  margin-top: 5px !important;
}
.icon-close {
  font-size: 28px !important;
}
.offcanvas {
  height: 100% !important;
  width: 80vw !important;
}
.icon-home-smart {
  background: url(../../img/Icons/Home.svg);
  width: 35px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.icon-home-smart-active {
  background: url(../../img/Icons/Home-active.svg);
  width: 35px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}
.link-smart {
  font-weight: 600 !important;
  font-size: 16px !important;
}
.container-smart {
  width: 100px;
  margin-left: 20px;
  cursor: pointer;
}
.marginButton-smart {
  margin-top: 20px;
}

/* codice button call informativa */

.container-button-call {
  margin-top: 18px !important;
}

@media (max-width: 600px) {
  .button-consulenza-smart {
    width: 90% !important;
  }
  .container-button-call {
    margin-left: 80px !important;
  }
}

@media (max-width: 500px) {
  .container-button-call {
    margin-left: 20px !important;
  }
}

@media (max-width: 450px) {
  .container-button-call {
    margin-top: 10px !important;
    margin-left: 0px !important;
  }

  .button-consulenza-smart {
    width: 85% !important;
  }
  .icon-phone {
    font-size: 18px !important;
  }
  .text-button-call {
    font-size: 12px !important;
    margin-top: 2px !important;
  }
}

@media (max-width: 350px) {
  .container-button-call {
    margin-left: 13px !important;
  }
  .button-consulenza-smart {
    width: 90% !important;
  }
}
