.container-AdvantagesTrademark {
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
}

.title-AdvantagesTrademark {
  font-size: 50px !important;
  font-weight: 600 !important;
  margin-bottom: 50px !important;
}

.container-AdvantagesTrademark-content {
  display: flex;
  margin-left: 100px !important;
}

.icon-team-trademark {
  background: url(../../img/Icons/Team.svg);
  width: 55px;
  height: 55px;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-pig {
  background: url(../../img/Icons/Savings.svg);
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-online-trademark {
  background: url(../../img/Icons/Online.svg);
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.description-AdvantagesTrademark {
  font-size: 20px !important;
  margin-left: 10px !important;
}

.container-text-AdvantagesTrademark {
  margin-bottom: 30px !important;
}

.container-img-AdvantagesTrademark {
  margin-left: 100px !important;
  margin-top: -50px !important;
}

.img-AdvantagesTrademark {
  width: 450px;
  height: 450px;
  background-image: url(../../img/graphic/marchio-grafica.svg) !important;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1350px) {
  .container-img-AdvantagesTrademark {
    margin-left: 20px !important;
    margin-top: -50px !important;
  }
  .img-AdvantagesTrademark {
    width: 450px;
    height: 400px;
  }
}

@media (max-width: 1180px) {
  .container-img-AdvantagesTrademark {
    margin-left: 0px !important;
    margin-top: 50px !important;
  }
  .img-AdvantagesTrademark {
    width: 380px;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1090px) {
  .container-img-AdvantagesTrademark {
    margin-left: 0px !important;
    margin-top: 50px !important;
  }
  .img-AdvantagesTrademark {
    width: 380px;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .description-AdvantagesTrademark {
    margin-left: 20px !important;
  }
}

@media (max-width: 1000px) {
  .container-img-AdvantagesTrademark {
    margin-left: 10px !important;
    margin-top: 100px !important;
  }
  .img-AdvantagesTrademark {
    width: 350px;
    height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 950px) {
  .container-img-AdvantagesTrademark {
    margin-left: 10px !important;
    margin-top: 100px !important;
  }
  .img-AdvantagesTrademark {
    width: 320px;
    height: 350px;

    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 900px) {
  .container-AdvantagesTrademark {
    display: flex;
    padding-top: 0px;
    padding-bottom: 80px;
  }

  .container-img-AdvantagesTrademark {
    display: flex;
    margin-left: 0px !important;
    justify-content: center;
    margin-top: 50px !important;
  }

  .container-AdvantagesTrademark-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .container-AdvantagesTrademark-content {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-left: 0px !important;
  }

  .img-AdvantagesTrademark {
    width: 500px;
    height: 350px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .title-AdvantagesTrademark {
    font-size: 48px !important;
    font-weight: 600 !important;
    margin-bottom: 50px !important;
  }
  .description-AdvantagesTrademark {
    font-size: 18px !important;
    margin-left: 10px !important;
  }
}

@media (max-width: 700px) {
  .description-AdvantagesTrademark {
    font-size: 18px !important;
    margin-left: 20px !important;
  }
}

@media (max-width: 600px) {
  .title-AdvantagesTrademark {
    font-size: 38px !important;
    font-weight: 600 !important;
    margin-bottom: 50px !important;
  }
  .title-AdvantagesTrademark {
    text-align: center !important;
    font-size: 38px !important;
  }
  .description-AdvantagesTrademark {
    font-size: 16px !important;
    margin-left: 40px !important;
  }
}

@media (max-width: 800px) {
  .icon-team-trademark {
    width: 45px;
    height: 45px;
  }

  .icon-pig {
    width: 45px;
    height: 45px;
  }

  .icon-online-trademark {
    width: 45px;
    height: 45px;
  }
}
@media (max-width: 400px) {
  .icon-team-trademark {
    margin-left: -10px;
  }

  .icon-pig {
    margin-left: -10px;
  }

  .icon-online-trademark {
    margin-left: -10px;
  }
}
