.container-card-services {
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 100px;
}

.title-card-services {
  font-size: 50px !important;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: 50px !important;
}

.box-card-service {
  height: 100%;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px !important;
  display: flex;
  justify-content: space-around;
}

.card-services {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 30px 15px 30px 15px;
  /* max-width: 300px !important; */
  flex-direction: column;
  min-height: 450px;
}

.container-icon {
  display: flex !important;
  justify-content: center !important;
}

.icon-srl {
  width: 120px;
  height: 120px;
  margin-top: -10px;
  margin-bottom: 10px;
  background: url(../../img/IconsServices/Company.svg);
  background-color: transparent !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.icon-shareTransfer {
  width: 120px;
  height: 120px;
  margin-top: -10px;
  margin-bottom: 10px;
  background: url(../../img/IconsServices/Shares-Static.svg);
  background-color: transparent !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.icon-sign {
  width: 120px;
  height: 120px;
  margin-top: -10px;
  margin-bottom: 10px;
  background: url(../../img/IconsServices/Sign-Static.svg);
  background-color: transparent !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.icon-trademark {
  width: 120px;
  height: 120px;
  margin-top: -10px;
  margin-bottom: 10px;
  background: url(../../img/IconsServices/Trademark-Static.svg);
  background-color: transparent !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.title-services {
  font-size: 24px !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
}

.container-button-services {
  display: flex;
  justify-content: center;
  margin-top: 10px !important;
}

.button-cta-services {
  border-radius: 5px;
  width: 200px;
  text-align: center;
  padding: 8px !important;
  transition: background-color 0.5s ease;
  position: absolute;
  bottom: 30px;
  border: 2px solid #7678ed !important;
  background-color: #fff;
}

.text-button-services {
  font-weight: 600;
  /* font-size: 18px; */
}

.button-cta-services:hover {
  background-color: #bfbdbd47;
}

.text-button-services {
  font-size: 18px !important;
  font-weight: 600 !important;
}

@media (max-width: 1200px) {
  .card-services {
    min-height: 420px !important;
    margin-bottom: 50px !important;
  }
}

@media (max-width: 900px) {
  .title-card-services {
    font-size: 48px !important;
    font-weight: 600 !important;
  }
  .title-card-services {
    margin-bottom: 30px !important;
  }
}

@media (max-width: 720px) {
  .card-services {
    min-height: 450px !important;
    margin-bottom: 50px !important;
  }
}

@media (max-width: 650px) {
  .card-services {
    min-height: 440px !important;
    margin-bottom: 50px !important;
  }
}

@media (max-width: 600px) {
  .card-services {
    min-height: 420px !important;
    margin-bottom: 50px !important;
  }
  .title-card-services {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
  .container-card-services {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
