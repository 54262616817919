.text-title {
  color: #001f3f !important;
  font-size: 14px;
  font-weight: bold !important;
}
.text-privacy {
  color: #001f3f !important;
  font-size: 14px !important;
}

.textLink {
  cursor: pointer;
  color: #ff9d1b;
  text-decoration: none;
  margin-left: 5px;
}
