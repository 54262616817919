.container-footer {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.text-azienda {
  margin-top: 20px;
  text-align: end;
}

.logo-footer {
  width: 90px;
  height: 80px;
  margin-top: -20px;
  margin-left: -20px;
  background: url(../../img/logo-svg-legal-farm/LagalFarm_favicon_negative.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.container-link-page-servizi {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.img-footer-badge {
  background: url(../../img/img-footer/badge.png);
  width: 60px !important;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
}

.container-logo-payment {
  display: flex;
  justify-content: center;
}

.container-badge-payment {
  margin-top: 30px !important;
  margin-bottom: 20px !important;
  /* padding-left: 10px; */
  /* margin-left: -30px !important; */
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) {
  .container-badge-payment {
    margin-left: 0px !important;
  }
}

@media (max-width: 600px) {
  .container-logo-footer {
    display: flex;
    justify-content: center;
  }
  .container-footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.link-footer {
  margin-left: 10px;
}

.img-footer-visa {
  background: url(../../img/img-footer/Visa.svg);
  width: 40px !important;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-footer-mastercard {
  background: url(../../img/img-footer/Mcard.svg);
  width: 40px !important;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-footer-amex {
  background: url(../../img/img-footer/Amex.svg);
  width: 40px !important;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-footer-paypal {
  background: url(../../img/img-footer/PP.svg);
  width: 40px !important;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-footer-stripe {
  background: url(../../img/img-footer/Stripe.svg);
  width: 40px !important;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 600px) {
  .container-logo-payment {
    margin-top: 20px !important;
  }
  .link-footer {
    margin-left: 0px !important;
    margin-top: 10px !important;
  }
  .container-link-page-servizi {
    display: flex !important;
    flex-direction: column !important;
    text-align: center !important;
  }
  .logo-footer {
    margin-left: 20px;
  }

  .text-azienda {
    text-align: center;
  }
}
