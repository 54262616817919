.container-modality-company {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.icon-modality {
  background: url(../../img/Icons/star.svg);
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 20px;
}

.container-content-modality-company {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px !important;
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.container-card-modality-company {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-card-modality {
  font-size: 24px !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
}

.card-modality-company {
  position: relative;
  background-color: #fff;
  border: 3px solid #001f3f;
  border-radius: 5px;
  text-align: center;
  padding: 30px 15px 30px 15px;
  flex-direction: column;
  min-height: 320px;
}

.title-modality-company {
  font-size: 50px !important;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: 70px !important;
}

@media (max-width: 1350px) {
  .card-modality-company {
    min-height: 335px;
  }
}

@media (max-width: 1250px) {
  .card-modality-company {
    min-height: 360px;
  }
}

@media (max-width: 1150px) {
  .card-modality-company {
    min-height: 430px;
  }
}

@media (max-width: 1000px) {
  .card-modality-company {
    min-height: 450px;
  }
}

@media (max-width: 900px) {
  .container-modality-company {
    height: 100%;
    display: flex;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .container-content-modality-company {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .card-modality-company {
    min-height: 200px;
    margin-top: 50px;
  }
  .title-modality-company {
    font-size: 48px !important;
    font-weight: 600 !important;
    margin-bottom: 0px !important;
  }
}

@media (max-width: 600px) {
  .container-modality-company {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .title-modality-company {
    font-size: 32px !important;
    font-weight: 600 !important;
  }
}
