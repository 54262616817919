body {
  margin: 0;
  padding: 0;
  background-color: #ffffff !important;

  /* font-family: "Inter", inter !important; */
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: "Poppins", sans-serif !important;
  font-style: normal !important;
}
